.App {
  height: 100vh;
  width: 100vw;
}

.file-container {
  max-width: 130px; 
}
.file-icon {
  width: 70px;
}
.folder-title {
  font-size: 0.8rem;
}
.letter {
  background-color: black;
  color: white;
  width: 100%;
  /* text-align: justify */
  /* height: 100%; */
}
.letter .footer {
  height: 100vh;
}
.width-fit {
  width: fit-content;
}

.margin-top-50 {
  margin-top: 50%;
}